export function checkImg(callback) {
  const imgArr = document.getElementsByTagName('img')
  let count = 0
  for (let index = 0; index < imgArr.length; index++) {
    const item = imgArr[index]
    item.onload = () => {
      count++
      if (count == imgArr.length) {
        callback()
      }
    }
  }
}