<template>
  <div class="page-header-container">
    <div class="navigation-bar-item" :class="isScroll ? 'scroll' : ''">
      <div class="content">
        <div class="logo-item">
          <img :src="isScroll ? blackLogo : whiteLogo" alt="偶遇圈">
        </div>
        <div class="nav-item">
          <div v-for="(item, index) in navData" :key="index" class="item" :class="id == item.id ? 'active' : ''" @click="handleJump(item.path)">
            <div class="link" :class="index == navData.length - 1 ? 'last' : ''">{{ item.title }}</div>
            <div class="line"></div>
          </div>
        </div>
      </div>
    </div>
    <van-popup v-model="loginVis" ><!-- @click="closeLogin()" -->
      <LoginN :key="1" ></LoginN>
    </van-popup>
  </div>
</template>

<script>

import { mapState } from 'vuex'
//import Login from './Login.vue'
import { defineAsyncComponent, ref } from 'vue';

//const loginVisGlobal = ref(false)

export default {
  name: 'NavigationComponent',
  //loginVisGlobal,
  components: {
    LoginN: defineAsyncComponent(() => import('./Login.vue')) 
  },
  props: {
    id: {
      type: Number,
      required: true,
      default: 1
    }
  },
  setup() {
    const loginVis = ref(false);
    const showLogin = () => {
      console.log("showlogin");
      loginVis.value = true;
    };
    return {
      loginVis,
      showLogin,
    };
  },
  data () {
    return {
      navData: [],
      isScroll: false,
      //loginVis: false,
      blackLogo: require('../assets/images/pc/logo-black.png'),
      whiteLogo: require('../assets/images/pc/logo-white.png')
    }
  },
  watch: {
    userInfo(val) {
      if (val) {
        this.handleSetMenu()
      }
    }
  },
  computed: {
    ...mapState(['token', 'userInfo'])
  },
  mounted () {
    this.handleSetMenu()
    const homePageHeader = document.getElementsByClassName('page-header-row')[0]
    if (homePageHeader) {
      window.onscroll = () => {
        const pageHeight = homePageHeader.offsetHeight
        const scrollHeight = document.documentElement.scrollTop
        if (scrollHeight > pageHeight - 77) {
          this.isScroll = true
        } else {
          this.isScroll = false
        }
      }
    } else {
      window.onscroll = () => {
        const scrollHeight = document.documentElement.scrollTop
        if (scrollHeight > 10) {
          this.isScroll = true
        } else {
          this.isScroll = false
        }
      }
    }
  },
  methods: {
    handleSetMenu () {
      const { token, userInfo } = this
      if (token) {
        this.navData = [
          { id: 1, title: '首页', path: '/' },
          { id: 2, title: '关于我们', path: '/about' },
          { id: 3, title: '常见问题', path: '/question' },
          { id: 4, title: '特惠充值', path: '/recharge' },
          { id: 5, title: userInfo.phone, path: '' }
        ]
      } else {
        this.navData = [
          { id: 1, title: '首页', path: '/' },
          { id: 2, title: '关于我们', path: '/about' },
          { id: 3, title: '常见问题', path: '/question' },
          { id: 4, title: '特惠充值', path: '/recharge' },
          { id: 5, title: '登录', path: '/login' }
        ]
      }
    },
    handleJump (path) {
      if (path == '/login') {
        this.loginVis = true
      } else {
        this.$router.push({ path })
      }
    },
    /*showLogin() {
      this.loginVis = true;
    },
    closeLogin() {
      this.loginVis = false;
    }*/
  }
}
</script>

<style lang="less" scoped>

.van-popup {
  width: 426px;
  height: 438px;
  border-radius: 20px;
  background: transparent;

}
.navigation-bar-item {
  width: 100%;
  height: 100px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  background: url(../assets/images/pc/pc-home-header-shade.png) no-repeat;
  background-size: auto 100%;
  transition: all .5s;
  &.scroll {
    background: #fff;
    .content {
      .nav-item {
        .item {
          border-right-color: rgba(0, 0, 0, .3);
          .link {
            color: #333;
          }
        }
      }
    }
  }
  .content {
    margin: 0 auto;
    width: 1170px;
    padding: 20px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .logo-item {
      width: 138px;
    }
    .nav-item {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .item {
        cursor: pointer;
        transition: all .5s;
        height: 38px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        &:nth-last-child(1) {
          border-right: 0;
        }
        &.active,
        &:hover {
          .link {
            color: #ffd321;
          }
          .line {
            background-color: #ffd321;
          }
        }
        .link {
          padding: 0 30px;
          border-right: 1px solid rgba(255, 255, 255, .3);
          transition: all .5s;
          height: 14px;
          color: #fff;
          font-size: 14px;
          line-height: 14px;
          margin: 10px 0;
        }
        .last {
          border-right: none;
        }
        .line {
          width: 20px;
          height: 4px;
          border-radius: 2px;
        }
      }
    }
  }
}
</style>
