<template>
  <div class="footer-item">
    <div class="btn-item">
      <a :href="upAppInfo.ios_down_url" target="_blank" rel="noopener noreferrer">
        <div class="btn">
          <img class="icon" src="../assets/images/pc/ios-icon.png" />
          <span>App Store</span>
        </div>
      </a>
      <a :href="upAppInfo.android_down_url" target="_blank" rel="noopener noreferrer">
        <div class="btn">
          <img class="icon" src="../assets/images/pc/android-icon.png" />
          <span>Android</span>
        </div>
      </a>
      <div class="btn pointer">
        <img class="icon" src="../assets/images/pc/qrcode-icon.png" />
        <div class="qrcode-item" style="display: none;">
          <img :src="upAppInfo.qrcode" />
        </div>
      </div>
    </div>
    <div class="nav-item">
      <a v-for="(item, index) in navData" :key="index" class="item" href="javascript:;"  @click="handleJump(item.path)">{{ item.title }}</a>
    </div>
    <van-popup v-model="loginVis" ><!-- @click="closeLogin()" -->
      <LoginF :key="3" ></LoginF>
    </van-popup>
    <div class="copyright">Copyright 2024 517date.com © All Rights Reserved · 偶遇圈   丨    滇ICP备 2022003321号-1</div>
  </div>
</template>

<script>

import { mapState } from 'vuex'
import { defineAsyncComponent, ref } from 'vue';
import { getSetting } from '@/api/index'

export default {
  name: 'FooterComponent',
  components: {
    LoginF: defineAsyncComponent(() => import('./Login.vue')) 
  },
  setup() {
    const loginVis = ref(false);
    const showLogin = () => {
      console.log("showlogin");
      loginVis.value = true;
    };
    return {
      loginVis,
      showLogin,
    };
  },
  data () {
    return {
      navData: [],
      upAppInfo: {}
    }
  },
  watch: {
    userInfo(val) {
      if (val) {
        this.handleSetMenu()
      }
    }
  },
  computed: {
    ...mapState(['token', 'userInfo'])
  },
  mounted () {
    this.handleGetSetting()
    this.handleSetMenu()
  },
  methods: {
    handleSetMenu () {
      const { token, userInfo } = this
      if (token) {
        this.navData = [
          { id: 1, title: '首页', path: '/' },
          { id: 2, title: '关于我们', path: '/about' },
          { id: 3, title: '常见问题', path: '/question' },
          { id: 4, title: '特惠充值', path: '/recharge' },
          { id: 5, title: userInfo.phone, path: '' }
        ]
      } else {
        this.navData = [
          { id: 1, title: '首页', path: '/' },
          { id: 2, title: '关于我们', path: '/about' },
          { id: 3, title: '常见问题', path: '/question' },
          { id: 4, title: '特惠充值', path: '/recharge' },
          { id: 5, title: '登录', path: '/login' }
        ]
      }
    },
    handleJump (path) {
      if (path == '/login') {
        this.loginVis = true
      } else {
        this.$router.push({ path })
      }
    },
    handleGetSetting () {
      getSetting({key: 'up_app'}).then(res => {
        try {
          this.upAppInfo = JSON.parse(res.info)
        } catch (error) {
          this.upAppInfo = res.info
        }
        console.log(this.upAppInfo);
      })
    }
  }
}
</script>

<style lang="less" scoped>
.van-popup {
  width: 426px;
  height: 438px;
  border-radius: 20px;
  background: transparent;

}
.footer-item {
  width: 100%;
  height: 288px;
  background-color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .btn-item {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #999;
    .btn {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
        border: 1px solid rgba(108, 117, 125, 0.25);
        background: rgba(108, 117, 125, 0.25);
      height: 45px;
      padding: 0 10px;
      border-radius: 6px;
      font-size: 17px;
      margin: 0 10px;
      transition: all 0.5s;
      cursor: pointer;
      &.pointer {
        cursor: pointer;
      }
      &:hover {
        color: #fff;
        border: 1px solid rgba(108, 117, 125, 0.65);
        background: rgba(108, 117, 125, 0.65);
        .qrcode-item {
          display: block !important;
        }
      }
      .icon {
        width: 26px;
        height: 26px;
      }
      span {
        margin-left: 10px;
        color: #fff;
      }
      .qrcode-item {
        position: absolute;
        top: -240px;
        left: -1px;
        width: 230px;
        height: 230px;
        background-color: #fff;
        border-radius: 6px;
        overflow: hidden;
      }
    }
  }
  .nav-item {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    .item {
      color: #919191;
      font-size: 15px;
      margin: 0 30px;
    }
  }
  .copyright {
    color: #919191;
    font-size: 15px;
    margin-top: 10px;
    margin-bottom: 50px;
  }
}
</style>
